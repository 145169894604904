<template>
  <div class="openPageBox">
    <p v-for="(item, index) in textList" :key="index">{{ item }}</p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      textNum: 0,
      timer: null,
      texts: [
        "监控脚本开启中...",
        "应用操作监控已开启！",
        "手机行为监控已运行!",
        "账号登录IP已核实记录！",
        "环境记录完成，设备监控中...",
      ],
      textList: ["", "", "", "", ""],
    }
  },
  methods: {
    addZero(val) {
      if (val < 10) {
        return '0' + val;
      } else {
        return val;
      }
    },
    formatTime(value, type) {
      if (!value) return null;

      if (!type) {
        type = 'YMDHMS';
      }
      var dataTime = '';

      var data = new Date(value);
      var year = data.getFullYear();
      var month = this.addZero(data.getMonth() + 1);
      var day = this.addZero(data.getDate());
      var hour = this.addZero(data.getHours());
      var minute = this.addZero(data.getMinutes());
      var second = this.addZero(data.getSeconds());
      if (type == 'YMD') {
        dataTime = year + '-' + month + '-' + day;
      } else if (type == 'YMDHMS') {
        dataTime =
          year +
          '-' +
          month +
          '-' +
          day +
          ' ' +
          hour +
          ':' +
          minute +
          ':' +
          second;
      } else if (type == 'YMDHM') {
        dataTime = year + '-' + month + '-' + day + ' ' + hour + ':' + minute;
      } else if (type == 'HMS') {
        dataTime = hour + ':' + minute + ':' + second;
      } else if (type == 'YM') {
        dataTime = year + '-' + month;
      } else if (type == 'YYYY') {
        dataTime = year;
      }
      return dataTime; //将格式化后的字符串输出到前端显示
    },
    displayTextOneByOne(text) {
      const delay = Math.floor(Math.random() * 20) + 0; // 1000ms到4000ms
      let index = 0;
      let str = '';
      text = this.formatTime(new Date()) + ' ' + text;
      const intervalId = setInterval(() => {
        if (index < text.length) {
          str += text[index];
          this.$set(this.textList, this.textNum, str)
          index++;
        } else {
          clearInterval(intervalId); // 清除定时器
          this.textNum++;
          if (this.textNum < 5) {
            this.displayTexts();
          }
        }
      }, delay);
    },
    // 随机延迟函数
    displayTexts(opt) {
      const randomDelay = Math.floor(Math.random() * 3000) + 1000; // 1000ms到4000ms
      if (opt) {
        this.displayTextOneByOne(this.texts[this.textNum], 100);
      } else {
        this.timer = setTimeout(() => {
          this.displayTextOneByOne(this.texts[this.textNum]); // 每个字的显示间隔为300毫秒
        }, randomDelay);
      }

    }
  },
  mounted() {
    // 开始显示文字
    this.displayTexts(true);
  }
}
</script>

<style>
html,
body {
  padding: 0;
  margin: 0;
  width:100%;
  height: 100%;
}
p{
  margin:0;
}

.openPageBox {
  background: #000;
  width: 100%;
  height: 100%;
  padding: 15px;
  line-height: 1.8;
  font-size: 13px;

  p {
    color: #257717;
  }
}
</style>